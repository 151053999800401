<template>
    <div style="width: 100%;height: 1000px">
        <div>username: <input v-model="userAcc"></div>
        <div>input: <input v-model="inputText" @keyup.enter="SendMessage()"><button @click="SendMessage()">click</button></div>
        <div style="margin: 10px">
            <div>ข้อความทั้งหมด</div>
            <div v-for="(item, index) in message" :key="index">
                <div>{{userAcc == item.username? 'Me' : item.username}} >> {{item.message}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pusher from 'pusher-js'
    import ServiceAPI from "../services/HttpClient";

    export default {
        data(){
            return{
                userAcc: "user1",
                inputText: null,
                pusher: null,
                channel: null,
                message: []
            }
        },
        methods: {
            SendMessage(){
                ServiceAPI.post('http://localhost:8000/api/sendMsg',{
                    username: this.userAcc,
                    msg: this.inputText
                }).then((resp)=>{
                    this.inputText = null;
                })
            }
        },
        created() {
            this.pusher = new Pusher("69a0d04e0e9d4fd1be03",{
                cluster: "ap1"
            })

            this.channel = this.pusher.subscribe('facebooklive_ch');
            this.channel.bind('facebooklive_resp', (data)=>{
                this.message.push(data)
                console.log(this.message)
            })
        }
    }
</script>

<style scoped>

</style>
